<template>
	<PageLayout :title="$t('deposit.title')">
		<div class="cashier-box cashier-box--deposit">
			<form id="gamechangersform" method="GET" action="https://uiservices.gamechangers.cc/hosted/default.aspx?">
				<input id="merchantID" type="hidden" name="merchantID" />
				<input id="url_redirect" type="hidden" name="url_redirect" />
				<input id="notification_url" type="hidden" name="notification_url" />
				<input id="trans_refNum" type="hidden" name="trans_refNum" />
				<input id="trans_amount" type="hidden" name="trans_amount" />
				<input id="trans_currency" type="hidden" name="trans_currency" />
				<input id="disp_paymentType" type="hidden" name="disp_paymentType" />
				<input type="hidden" name="method" value="gamechangers" />
				<input id="signature" type="hidden" name="signature" />
			</form>

			<payment-logo :custom-logo="'cards.svg'" />

			<div class="small-width overflow-visible">
				<payment-wallet />
			</div>

			<div class="line-separator-wrapper">
				<div class="line-separator" />
			</div>
			<amount-buttons
				:buttons-value="currentMethod.predefinedDepositAmounts"
				:form-amount-value="form.amount || currentMethod.predefinedDepositAmounts[0]"
				:currency="'USD'"
				:form-errors="formErrors['amount']"
				:current-method="currentMethod"
				@set-button-value="setButtonValue"
				@input-amount-value-validation="setFormValidation"
			/>

			<div>
				<span class="amount-error error-message">{{ formErrors.amount }}</span>
				<button type="button" class="button primary-button deposit center" :disabled="!form.validated" @click="pay()">
					{{ $t('deposit.top-up') }}
				</button>
			</div>
		</div>
		<div class="method-description-cashier">
			{{ $t(`deposit.method-descriptions.brcards`) }}
		</div>
	</PageLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../../../api';

const paymentLogo = () => import('../../../../shared/payment-logo/PaymentLogo');
const amountButtons = () => import('../../../../shared/amount-buttons/AmountButtons');
const paymentWallet = () => import('../../../../shared/payment-wallet/PaymentWallet');

export default {
	name: 'DepositGameChangers',
	components: {
		paymentLogo,
		PageLayout,
		amountButtons,
		paymentWallet,
	},
	data() {
		return {
			form: {
				amount: null,
				validated: true,
			},
			formErrors: {
				amount: null,
			},
			walletId: null,
		};
	},
	computed: {
		...mapGetters(['isEmbedded']),
		currentMethod() {
			return this.$store.getters.getCurrentMethod('gamechangers');
		},
	},
	async mounted() {
		await this.getUserWallet();
	},
	methods: {
		async getUserWallet() {
			try {
				const { id } = await apiClient.fetchFullWallet(this.$route.params.walletId);
				this.walletId = id;
			} catch (e) {
				this.error = 'wallets.failed-to-load-wallet';
			} finally {
				this.loading = false;
			}
		},
		async createTransaction(amount, currency, redirectUrl, webhookUrl) {
			const response = await apiClient.gameChangersCreateTransaction(
				this.walletId,
				amount,
				currency,
				redirectUrl,
				webhookUrl
			);

			if (response.status === 200) {
				const { signature, merchantId, transactionId } = response.data;
				return { signature, merchantId, transactionId };
			}
			throw new Error('Invalid response from create transaction');
		},
		async pay() {
			try {
				const currency = 'USD';
				const webhookUrl = `${process.env.VUE_APP_URL}/payment/gamechangers/webhook`;
				const redirectUrl = `${window.location.origin}/payments/deposit/confirmation`;

				const result = await this.createTransaction(this.form.amount, currency, redirectUrl, webhookUrl);

				document.getElementById('merchantID').value = result.merchantId;
				document.getElementById('url_redirect').value = redirectUrl;
				document.getElementById('notification_url').value = webhookUrl;
				document.getElementById('trans_refNum').value = result.transactionId;
				document.getElementById('trans_amount').value = this.form.amount.toString();
				document.getElementById('trans_currency').value = 'USD';
				document.getElementById('disp_paymentType').value = 'CC';
				document.getElementById('signature').value = result.signature;

				const form = document.getElementById('gamechangersform');
				form.submit();
			} catch (e) {
				if (e.response?.status === 422) {
					const { errors } = e.response.data;
					errors.forEach(({ param, msg }) => {
						this.formErrors[param] = msg;
					});
					return;
				}

				const walletId = this.$route.params.walletId;
				this.$router.push({
					path: `/payments/deposit/${walletId}/confirmation`,
					query: { module: 'Deposit', status: 'error' },
				});
			}
		},
		setButtonValue(event) {
			this.form.amount = event;
		},
	},
};
</script>

<style scoped>
#perfectmoneyRedir {
	visibility: hidden;
}

.error-message {
	margin-bottom: 10px;
}
</style>
